<template>
  <Head>
    <!-- Social -->
    <meta property="og:image" :content="`${ BASE_URL }og_image_trailer.jpg`">
    <meta property="og:image:type" content="image/jpeg">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
  </Head>

  <main class="main">
    <picture>
      <source media="(max-width: 599px)" srcset="@/assets/main_desktop.jpg, @/assets/main_desktop-2x.jpg 2x">
      <source media="(min-width: 600px)" srcset="@/assets/main_desktop.jpg, @/assets/main_desktop-2x.jpg 2x">
      <img class="bg" src="@/assets/main_desktop.jpg" alt="Mao and Rex look at each other by the sea" loading="lazy">
    </picture>
    <div class="desc">
      <p>婚宴將近，表單頁已關閉。若有任何需求請直接聯繫新人</p>
    </div>
  </main>
  <Wave reverse />
</template>

<script>
import {
  defineAsyncComponent,
} from 'vue';
export default {
  components: {
    Wave: defineAsyncComponent(() => import('@/components/Wave.vue')),
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  height: 100vh;
}
.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.desc {
  position: absolute;
  width: 100%;
  z-index: 2;
  text-align: center;
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.7;
  padding: 10rem 2rem;

  @media (min-width: 600px) {
    font-size: 4rem;
  }
}
</style>
